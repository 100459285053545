import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import {
  Button,
  TextField,
  MuiThemeProvider,
  createMuiTheme,
  FormControl,
  InputLabel,
  Tooltip,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import { useHistory, useParams } from "react-router-dom";
import { useSkeleton } from "../../../hooks/useSkeleton";
import { alertError, alertSuccess } from "../../../../utils/logger";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import { getBotById, postBot, updateBot, deleteBot } from "../../../../api/bot";
import DaysTabBlock from "../../../components/DaysTabBlock";
import { buttonsStyle } from "../../../components/tables/table";
import { getStrategies } from "../../../../api/strategy";

// Create theme for delete button (red)
const theme = createMuiTheme({
  palette: {
    secondary: {
      main: "#F64E60",
    },
  },
});

const weekdaysLabels = {
  monday: "Lunes",
  tuesday: "Martes",
  wednesday: "Miércoles",
  thursday: "Jueves",
  friday: "Viernes",
  saturday: "Sábado",
  sunday: "Domingo",
};

const weekdays = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

let initialOfficeHours = {};
weekdays.forEach(
  (day) =>
    (initialOfficeHours = {
      ...initialOfficeHours,
      [day]: [
        {
          start: null,
          end: null,
        },
      ],
    })
);

function getEmptyBot() {
  return {
    fullName: "",
    executionHours: initialOfficeHours,
    strategies: [],
    active: true,
  };
}

export default function EditBotsPage() {
  const [bot, setBot] = useState(getEmptyBot());
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [strategies, setStrategies] = useState([]);
  const botId = useParams().id;
  const history = useHistory();

  const {
    isLoading: isLoadingData,
    disableLoading: disableLoadingData,
    ContentSkeleton,
  } = useSkeleton();

  useEffect(() => {
    getStrategies()
      .then((res) => {
        if (res.status === 200) {
          console.log("STRATEGIES: ", res.data);
          setStrategies(res.data);
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "Could not get strategies.",
        });
      });
    if (!botId) {
      disableLoadingData();
      return;
    }
    getBotById(botId)
      .then((res) => {
        if (res.status === 200) {
          setBot(res.data);
          disableLoadingData();
        }
      })
      .catch((error) => {
        alertError({ error: error, customMessage: "Could not get bot." });
        history.push("/bots");
      });
  }, [botId, disableLoadingData, history]);

  function saveBot() {
    let saveBot = bot;
    if (!botId) {
      postBot(saveBot)
        .then((res) => {
          if (res.status === 201) {
            alertSuccess({
              title: "Saved!",
              customMessage: "Bot successfully created.",
            });
            history.push("/bots");
          }
        })
        .catch((error) => {
          alertError({ error: error, customMessage: "Could not save bot." });
        });
    } else {
      updateBot(botId, saveBot)
        .then((res) => {
          if (res.status === 200) {
            alertSuccess({
              title: "Saved!",
              customMessage: "Changes successfully saved.",
            });
            history.push("/bots");
          }
        })
        .catch((error) => {
          alertError({
            error: error,
            customMessage: "Could not save changes.",
          });
        });
    }
  }

  // const handleChange = (element) => (event) => {
  //   setBot({ ...bot, [element]: event.target.value });
  // };

  const renderDaysTabContentOfficeHours = (day) => {
    return (
      <>
        <br />
        {bot?.executionHours &&
          bot.executionHours[day]?.map((item, index) => {
            const slot = `Franja horaria ${index + 1}`;
            return (
              <React.Fragment key={index}>
                <h5>{slot}</h5>
                <div className="row">
                  <div className="col-11">
                    <TextField
                      id={`${day} ${slot} start time`}
                      label={`Hora de inicio`}
                      value={item?.start || ""}
                      onChange={handleChange(
                        "executionHours",
                        day,
                        "start",
                        index
                      )}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      type="time"
                      margin="normal"
                      variant="outlined"
                      required
                    />
                    <TextField
                      id={`${day} ${slot} end time`}
                      label={`Hora de fin`}
                      value={item?.end || ""}
                      onChange={handleChange(
                        "executionHours",
                        day,
                        "end",
                        index
                      )}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      type="time"
                      margin="normal"
                      variant="outlined"
                      required
                    />
                  </div>
                  <div className="col-1 d-flex align-items-center">
                    <Tooltip title="Eliminar">
                      <span>
                        <Button
                          size="small"
                          style={buttonsStyle}
                          disabled={bot.executionHours[day]?.length === 1}
                          onClick={() => {
                            let _hours = bot.executionHours;
                            _hours[day].splice(index, 1);
                            setBot({ ...bot, executionHours: _hours });
                          }}
                        >
                          <Delete />
                        </Button>
                      </span>
                    </Tooltip>
                  </div>
                </div>
                <br />
                <br />
              </React.Fragment>
            );
          })}
        <br />
        <Button
          onClick={() => {
            let _hours = bot.executionHours;
            if (_hours) {
              if (_hours[day]) _hours[day].push({ start: null, end: null });
              else _hours[day] = [{ start: null, end: null }];
            } else _hours = initialOfficeHours;
            setBot({ ...bot, executionHours: _hours });
          }}
          variant="outlined"
          color="primary"
        >
          Añadir nueva franja horaria
        </Button>
      </>
    );
  };

  const handleChange = (element, day, subelement, index) => (event) => {
    if (event.target.value === " ") return;
    if (day) {
      let newText = bot[element];
      const slots = [...newText[day]];
      const aux = { ...slots[index], [subelement]: event.target.value };
      slots.splice(index, 1, aux);
      newText = { ...newText, [day]: slots };
      setBot({ ...bot, executionHours: newText });
    } else setBot({ ...bot, [element]: event.target.value });
  };

  // useEffect(() => {
  //   console.log("BOT", bot);
  // }, [bot]);

  if (isLoadingData) return <ContentSkeleton />;
  else
    return (
      <>
        <Card>
          <CardHeader title="Edit bot"></CardHeader>
          <CardBody>
            <TextField
              id={`fullName`}
              label="Full name"
              value={bot.fullName}
              onChange={handleChange("fullName")}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              required
            />
            <br />
            <FormControl variant="outlined" style={{ minWidth: 120 }}>
              <InputLabel id="strategies-label">Strategies</InputLabel>
              <Select
                labelId="strategies-label"
                id="strategies-select"
                multiple
                value={bot.strategies}
                onChange={handleChange("strategies")}
                renderValue={(selected) => {
                  const foundStrategies = strategies.filter((s) =>
                    selected.includes(s._id)
                  );
                  return foundStrategies.map((s) => s.fullName).join(", ");
                }}
              >
                {strategies.map((strategy) => (
                  <MenuItem key={strategy._id} value={strategy._id}>
                    {strategy.fullName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <br />
            <CardHeader title="Horario"></CardHeader>
            <CardBody>
              <DaysTabBlock
                daysTabContent={renderDaysTabContentOfficeHours}
                days={weekdays}
                daysLabels={weekdaysLabels}
              />
            </CardBody>
            <br />
            <FormControlLabel
              control={
                <Checkbox
                  checked={bot.active}
                  onChange={() => setBot({ ...bot, active: !bot.active })}
                  name="checkActive"
                />
              }
              label="Active"
            />
          </CardBody>
        </Card>
        <Button
          onClick={() => history.push("/bots")}
          variant="outlined"
          style={{ marginRight: "20px" }}
        >
          Back
        </Button>
        <Button
          onClick={() => saveBot()}
          variant="outlined"
          color="primary"
          style={{ marginRight: "20px" }}
        >
          Save bot
        </Button>
        {botId && (
          <>
            <MuiThemeProvider theme={theme}>
              <Button
                onClick={() => setOpenConfirmDialog(true)}
                variant="outlined"
                color="secondary"
              >
                Delete bot
              </Button>
            </MuiThemeProvider>

            <ConfirmDialog
              title={"Are you sure you want to delete this bot?"}
              open={openConfirmDialog}
              setOpen={setOpenConfirmDialog}
              onConfirm={() => {
                deleteBot(botId)
                  .then((res) => {
                    if (res.status === 204 || res.status === 200) {
                      alertSuccess({
                        title: "Deleted!",
                        customMessage: "Bot deleted successfully",
                      });
                      history.push("/bots");
                    }
                  })
                  .catch((error) => {
                    alertError({
                      error: error,
                      customMessage: "Could not delete bot.",
                    });
                  });
              }}
            />
          </>
        )}
      </>
    );
}
