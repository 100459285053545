import { authClient, API } from "../index";

// Get all strategies
export const getStrategies = () => {
  return authClient().get(`${API}/strategy?getDisabled=true`);
};

// Get strategy by id
export const getStrategyById = (id) => {
  return authClient().get(`${API}/strategy/${id}`);
};

// Delete strategy
export const deleteStrategy = (id) => {
  return authClient().delete(`${API}/strategy/${id}`);
};

// Create strategy
export const postStrategy = async (strategy) => {
  return authClient().post(`${API}/strategy`, strategy);
};

// Update strategy
export const updateStrategy = async (id, strategy) => {
  return authClient().put(`${API}/strategy/${id}`, strategy);
};

// Change status strategy
export const changeStatusStrategy = async (id, active) => {
  return authClient().put(`${API}/strategy/change-status/${id}`, { active });
};

export const getAllCoins = async (exchange) => {
  return authClient().get(`${API}/strategy/coins`, { params: { exchange } });
};
