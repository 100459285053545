import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import Table, {
  dateFormatter,
  buttonsStyle,
} from "../../../components/tables/table";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import { Button, Tooltip, Switch, FormControlLabel } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { alertError, alertSuccess } from "../../../../utils/logger";
import { useHistory } from "react-router-dom";
import ToggleOffIcon from "@material-ui/icons/ToggleOff";
import ToggleOnIcon from "@material-ui/icons/ToggleOn";
import { shallowEqual, useSelector } from "react-redux";
import FiltersCard from "../../../components/filters/Filter";
import { getBots, deleteBot, changeStatusBot } from "../../../../api/bot";

function getData(bots) {
  let data = [];
  for (let i = 0; i < bots.length; ++i) {
    const elem = {};
    elem.name = bots[i].fullName;
    elem.createdAt = bots[i].createdAt;
    elem.id = bots[i]._id;
    elem.active = bots[i].active;
    data = data.concat(elem);
  }
  return data;
}

const initialFilters = {
  active: false,
};

export default function BotsPage() {
  const [data, setData] = useState([]);
  const [botId, setbotId] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [bots, setBots] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [collapsed, setCollapsed] = useState(true);
  const [filterOptions, setFilterOptions] = useState(initialFilters);
  const history = useHistory();
  const user = useSelector((store) => store.authentication?.user, shallowEqual);

  function buttonFormatter(cell) {
    const elem = data.find((item) => item._id === cell);
    return (
      <>
        <Tooltip title="Edit">
          <Button
            style={buttonsStyle}
            size="small"
            onClick={() => history.push("/edit-bot/" + cell)}
          >
            <EditIcon />
          </Button>
        </Tooltip>
        {user?.role === "admin" && (
          <>
            <Tooltip title={elem?.active ? "Disable" : "Enable"}>
              <Button
                style={buttonsStyle}
                size="small"
                onClick={() => {
                  setbotId(elem);
                  setOpenConfirmDialog(1);
                }}
              >
                {elem?.active ? (
                  <ToggleOffIcon />
                ) : (
                  <ToggleOnIcon style={{ color: "red" }} />
                )}
              </Button>
            </Tooltip>
            <Tooltip title="Delete">
              <Button
                style={buttonsStyle}
                size="small"
                onClick={() => {
                  setbotId(elem);
                  setOpenConfirmDialog(2);
                }}
              >
                <DeleteIcon />
              </Button>
            </Tooltip>
          </>
        )}
      </>
    );
  }

  const columns = [
    { dataField: "name", text: "Full name", sort: true },
    {
      dataField: "createdAt",
      text: "Created at",
      formatter: dateFormatter,
      sort: true,
    },
    { dataField: "id", text: "", formatter: buttonFormatter },
  ];

  useEffect(() => {
    getBots()
      .then((res) => {
        if (res.status === 200) {
          setData(res.data);
          setFilteredData(res.data);
          setBots(res.data);
          setRefresh(false);
        }
      })
      .catch((error) => {
        alertError({ error: error, customMessage: "Could not get bots." });
      });
  }, [refresh]);

  const handleSearch = async () => {
    if (!data.length) return;
    setFilteredData(
      data.filter((item) => {
        let filter = true;
        if (filterOptions.active) filter = filter && item.active;
        if (filter) return item;
        return false;
      })
    );
  };

  const handleClearFilters = () => {
    setFilterOptions(initialFilters);
    setRefresh(true);
  };

  const renderFiltersContent = () => {
    return (
      <>
        <br />
        <FormControlLabel
          control={
            <Switch
              checked={filterOptions.active}
              onChange={() =>
                setFilterOptions({
                  ...filterOptions,
                  active: !filterOptions.active,
                })
              }
              name="checkActive"
            />
          }
          label={filterOptions.active ? "Only active" : "All active/inactive"}
        />
      </>
    );
  };

  return (
    <>
      <Card>
        <CardHeader title="Bots list">
          <CardHeaderToolbar>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => history.push("/edit-bot")}
            >
              Add new
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <FiltersCard
            filtersContent={renderFiltersContent}
            collapsed={collapsed}
            setCollapsed={setCollapsed}
            handleClearFilters={handleClearFilters}
            handleSearch={handleSearch}
          />

          <Table data={getData(filteredData, bots)} columns={columns} />

          <ConfirmDialog
            title={`Are you sure you want to ${
              botId?.active ? "disable" : "enable"
            } this bot?`}
            open={openConfirmDialog === 1}
            setOpen={setOpenConfirmDialog}
            onConfirm={() => {
              changeStatusBot(botId._id, !botId?.active)
                .then((res) => {
                  if (res.status === 200) {
                    alertSuccess({
                      title: `${botId?.active ? "Disabled!" : "Enabled!"}`,
                      customMessage: `bot ${
                        botId?.active ? "disabled" : "enabled"
                      } successfully`,
                    });
                    setRefresh(true);
                  }
                })
                .catch((error) => {
                  alertError({
                    error: error,
                    customMessage: `Could not ${
                      botId?.active ? "disable" : "enable"
                    } bot.`,
                  });
                });
            }}
          />
          <ConfirmDialog
            title={"Are you sure you want to remove this bot?"}
            open={openConfirmDialog === 2}
            setOpen={setOpenConfirmDialog}
            onConfirm={() => {
              deleteBot(botId._id)
                .then((res) => {
                  if (res.status === 204 || res.status === 200) {
                    alertSuccess({
                      title: "Deleted!",
                      customMessage: "bot removed successfully.",
                    });
                    setRefresh(true);
                  }
                })
                .catch((error) => {
                  alertError({
                    error: error,
                    customMessage: "Could not remove bot.",
                  });
                });
            }}
          />
        </CardBody>
      </Card>
    </>
  );
}
