import React from "react";
import { Redirect, Switch } from "react-router-dom";
import { ContentRoute } from "../_metronic/layout";
import { shallowEqual, useSelector } from "react-redux";
import { DashboardPage } from "./pages/DashboardPage";
import AdminsPage from "./modules/ContentManager/admins/AdminsPage";
import EditAdminsPage from "./modules/ContentManager/admins/EditAdminsPage";
import TextsPage from "./modules/ContentManager/texts/TextsPage";
import EditTextsPage from "./modules/ContentManager/texts/EditTextsPage";
import LanguagesPage from "./modules/ContentManager/languages/LanguagesPage";
import EditLanguagesPage from "./modules/ContentManager/languages/EditLanguagesPage";
import BotsPage from "./modules/ContentManager/bots/BotsPage";
import EditBotsPage from "./modules/ContentManager/bots/EditBotsPage";
import StrategiesPage from "./modules/ContentManager/strategies/StrategiesPage";
import EditStrategyPage from "./modules/ContentManager/strategies/EditStrategyPage";
import StatisticsPage from "./modules/ContentManager/statistics/StatisticsPage";

export default function BasePage() {
  const user = useSelector((store) => store.authentication?.user, shallowEqual);
  const isAdmin = user || user?.role !== "admin";

  return (
    <Switch>
      {/* Redirect from root URL to /dashboard. */}
      <Redirect exact from="/" to="/dashboard" />
      <ContentRoute path="/dashboard" component={DashboardPage} />

      {/* USERS */}
      {/* Administrators */}
      <ContentRoute from="/admins" component={AdminsPage} />
      <ContentRoute from="/edit-admin/:id?" component={EditAdminsPage} />
      {/* BOTS & STRATEGIES*/}
      <ContentRoute from="/bots" component={BotsPage} />
      <ContentRoute from="/edit-bot/:id?" component={EditBotsPage} />
      <ContentRoute from="/strategies" component={StrategiesPage} />
      <ContentRoute from="/edit-strategy/:id?" component={EditStrategyPage} />
      {/* STATISTICS */}
      <ContentRoute from="/statistics" component={StatisticsPage} />
      {/* MISCELLANY */}
      {/* Languages */}
      <ContentRoute
        from="/languages"
        component={!isAdmin ? DashboardPage : LanguagesPage}
      />
      <ContentRoute
        from="/edit-language/:id?"
        component={!isAdmin ? DashboardPage : EditLanguagesPage}
      />
      {/* Texts */}
      <ContentRoute
        from="/texts"
        component={!isAdmin ? DashboardPage : TextsPage}
      />
      <ContentRoute
        from="/edit-text/:id"
        component={!isAdmin ? DashboardPage : EditTextsPage}
      />

      <Redirect to="/error" />
    </Switch>
  );
}
