import { useState, useEffect, useRef, useCallback } from "react";
import ContentSkeleton from "../modules/Skeleton/ContentSkeleton";

export const useSkeleton = () => {
  const [isLoading, setLoading] = useState(true);
  const timeoutRef = useRef(null);

  const disableLoading = useCallback(() => {
    timeoutRef.current = setTimeout(() => setLoading(false), 400);
  }, []);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return {
    isLoading,
    disableLoading,
    ContentSkeleton,
  };
};
