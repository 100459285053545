import { authClient, API } from "../index";

// Get all bots
export const getBots = () => {
  return authClient().get(`${API}/bot?getDisabled=true`);
};

// Get bot by id
export const getBotById = (id) => {
  return authClient().get(`${API}/bot/${id}`);
};

// Delete bot
export const deleteBot = (id) => {
  return authClient().delete(`${API}/bot/${id}`);
};

// Create bot
export const postBot = async (bot) => {
  return authClient().post(`${API}/bot`, bot);
};

// Update bot
export const updateBot = async (id, bot) => {
  return authClient().put(`${API}/bot/${id}`, bot);
};

// Change status bot
export const changeStatusBot = async (id, active) => {
  console.log("id", id, "active", active);
  return authClient().put(`${API}/bot/change-status/${id}`, { active });
};
