import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import Table from "../../../components/tables/table";
import { useHistory } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import OPERATIONS from "./data.json";

const calculateProfitPercentages = (operations) => {
  const profits = { long: 0, short: 0, totalLong: 0, totalShort: 0 };

  operations.forEach((op) => {
    if (op.closed) {
      if (op.action === "ENTER_LONG") {
        profits.totalLong++;
        if (op.success) profits.long++;
      } else if (op.action === "ENTER_SHORT") {
        profits.totalShort++;
        if (op.success) profits.short++;
      }
    }
  });

  profits.longPercent =
    ((profits.long / profits.totalLong) * 100).toFixed(2) || 0;
  profits.shortPercent =
    ((profits.short / profits.totalShort) * 100).toFixed(2) || 0;

  return profits;
};

const calculateCurrencyPairRankings = (operations) => {
  const pairSuccessRates = {};

  operations.forEach((op) => {
    if (!pairSuccessRates[op.instrument]) {
      pairSuccessRates[op.instrument] = { success: 0, total: 0 };
    }

    if (op.closed) {
      pairSuccessRates[op.instrument].total++;
      if (op.success) pairSuccessRates[op.instrument].success++;
    }
  });

  const bestTenPairs = Object.entries(pairSuccessRates)
    .map(([pair, data]) => ({
      pair,
      successRate: (data.success / data.total) * 100,
    }))
    .sort((a, b) => b.successRate - a.successRate)
    .slice(0, 14);

  const worstTenPairs = Object.entries(pairSuccessRates)
    .map(([pair, data]) => ({
      pair,
      successRate: (data.success / data.total) * 100,
    }))
    .sort((a, b) => a.successRate - b.successRate)
    .slice(0, 14);

  return { bestTenPairs, worstTenPairs };
};

export default function StatisticsPage() {
  const history = useHistory();
  const user = useSelector((store) => store.authentication?.user, shallowEqual);
  console.log("OPERATIONS", OPERATIONS);

  const CLOSEDOPERATIONS = OPERATIONS.filter(
    (operation) => operation.closed === true
  );
  const SUCCESSOPERATIONS = CLOSEDOPERATIONS.filter(
    (operation) => operation.success === true
  );
  const FAILOPERATIONS = CLOSEDOPERATIONS.filter(
    (operation) => operation.success === false
  );

  const profitStats = calculateProfitPercentages(CLOSEDOPERATIONS);
  const { bestTenPairs, worstTenPairs } = calculateCurrencyPairRankings(
    CLOSEDOPERATIONS
  );

  const profitData = [
    {
      name: "Long",
      success: profitStats.longPercent,
      total: profitStats.totalLong,
    },
    {
      name: "Short",
      success: profitStats.shortPercent,
      total: profitStats.totalShort,
    },
  ];

  return (
    <>
      <Card>
        <CardHeader title="Statistics">
          <CardHeaderToolbar></CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <section>
            <Card>
              <CardHeader title="Currency Pair Rankings"></CardHeader>
              <CardBody>
                <div className="d-flex flex-row justify-content-around">
                  <section>
                    <strong style={{ fontSize: "20px" }}>Best Pairs</strong>
                    <Table
                      data={bestTenPairs}
                      columns={[
                        { dataField: "pair", text: "Pair", sort: true },
                        {
                          dataField: "successRate",
                          text: "Success Rate (%)",
                          sort: true,
                        },
                      ]}
                      search={false}
                      className="mx-2"
                    />
                  </section>
                  <section>
                    <strong style={{ fontSize: "20px" }}>Worst Pairs</strong>
                    <Table
                      data={worstTenPairs}
                      columns={[
                        { dataField: "pair", text: "Pair", sort: true },
                        {
                          dataField: "successRate",
                          text: "Success Rate (%)",
                          sort: true,
                        },
                      ]}
                      search={false}
                    />
                  </section>
                </div>
              </CardBody>
            </Card>
          </section>
          <section className="d-flex flex-row justify-content-around">
            {/* WinRate Operations */}
            <Card>
              <CardHeader title="WinRate Operations"></CardHeader>
              <CardBody className="d-flex flex-column">
                <span className="lead">
                  <strong>Closed operations: </strong> {CLOSEDOPERATIONS.length}
                </span>
                <span className="lead">
                  <strong>Winned: </strong> {SUCCESSOPERATIONS.length}
                </span>
                <span className="lead">
                  <strong>WinRate: </strong>
                  {parseFloat(
                    SUCCESSOPERATIONS.length / OPERATIONS.length
                  ).toFixed(2)}
                  %
                </span>
              </CardBody>
            </Card>
            {/* TOTAL NUMBER OF BOT ENTRIES*/}
            <Card>
              <CardHeader title="Total Number of Bot Entries"></CardHeader>
              <CardBody className="d-flex flex-column">
                <span className="lead">
                  <strong>Total: </strong> {OPERATIONS.length}
                </span>
              </CardBody>
            </Card>
          </section>
          <section className="d-flex align-items-center justify-content-center">
            <strong style={{ fontSize: "20px" }}>
              LONG/SHORT SUCCESS RATIO
            </strong>
            <BarChart
              width={600}
              height={400}
              data={profitData}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="success" fill="#8884d8" />
            </BarChart>
          </section>
        </CardBody>
      </Card>
    </>
  );
}
